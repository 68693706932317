import React, { memo } from 'react';
import { StyledLinkNavigationItem } from './styled';
import { Link, NavigationNode } from '~/lib/data-contract';

type Props = {
    index: number;
    link?: Link;
    subNodes?: NavigationNode[];
    active?: boolean;
    onClick?: () => void;
    onHover?: (index: number) => void;
    onMouseLeave?: () => void;
};

/**
 * Navigation item, which will render either as a link or button, based on it's childnodes presence.
 * has a active and inactive state.
 */
export const NavigationItem = memo((props: Props) => {
    const { index, link, subNodes, onHover, onMouseLeave, onClick, ...rest } = props;

    if (subNodes?.length) {
        return (
            <StyledLinkNavigationItem
                variant="plain"
                children={link?.title}
                href={link?.url}
                onMouseEnter={() => onHover?.(index)}
                onMouseLeave={onMouseLeave}
                aria-expanded={props.active}
                aria-label={link?.title?.toLocaleLowerCase()}
                {...rest}
            />
        );
    }

    if (link?.url) {
        return (
            <StyledLinkNavigationItem
                onClick={onClick}
                onMouseEnter={() => onHover?.(index)}
                href={link.url}
                children={link.title}
                aria-label={link?.title?.toLocaleLowerCase()}
                {...rest}
            />
        );
    }

    return <></>;
});
