import React, { memo } from 'react';
import {
    CenterText,
    Gradient,
    NodeText,
    StyledItem,
    StyledMenu,
    StyledMenuWrapper,
    SubMenu,
    SubMenuItem,
} from './styled';
import { NavigationLink } from '~/features/navigation/components/N30MegaMenu/components/NavigationLink';
import { ImageMedia, NavigationNode } from '~/lib/data-contract';
import { Image } from '~/shared/components';
import { weakKey } from '~/shared/utils/jsx';
export const Menu = memo(({ nodes, image }: { nodes: NavigationNode[]; image?: ImageMedia }) => (
    <StyledMenuWrapper>
        <StyledMenu>
            {nodes?.map((subNode) => {
                const count = subNode.children?.length ?? 0;
                const isSingle = count <= 11;
                const switchCount = isSingle ? count : Math.ceil(count / 2) + 1;

                return (
                    <ul key={subNode.id}>
                        <StyledItem
                            href={subNode.link.url}
                            target={subNode.link.target}
                            title={subNode.link.title}
                        >
                            {image && (
                                <>
                                    <Image
                                        height={172}
                                        width={338}
                                        objectFit="cover"
                                        sizes="25vw"
                                        src={subNode.image?.src as string}
                                        alt={subNode.image?.alt ?? 'Navigation image'}
                                    />
                                    <Gradient></Gradient>
                                </>
                            )}

                            <CenterText>
                                <NodeText variant="navigationHeader">{subNode.link.text}</NodeText>
                            </CenterText>
                        </StyledItem>
                        <SubMenu grow>
                            <SubMenuItem column>
                                {subNode.children?.slice(0, switchCount).map(({ link }) => (
                                    <NavigationLink key={weakKey(link)} link={link} />
                                ))}
                            </SubMenuItem>
                            {!isSingle && (
                                <SubMenuItem column>
                                    {subNode.children?.slice(switchCount).map(({ link }) => (
                                        <NavigationLink key={weakKey(link)} link={link} />
                                    ))}
                                </SubMenuItem>
                            )}
                        </SubMenu>
                    </ul>
                );
            })}
        </StyledMenu>
    </StyledMenuWrapper>
));
