import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import { useFrame } from '~/shared/utils';
import { Drawer } from '../../../../shared/components/Drawer';
import { MobileMegaMenu } from './components/MobileMegaMenu';
import { useMobileMenu } from './hooks/useMobileMenu';
import { breakpointsRaw } from '~/theme/breakpoints';

/**
 * Mega menu for mobile. This menu renders each step as an menu page.
 */
export const N35MobileMegaMenu = () => {
    const { isOpen, close } = useMobileMenu();
    const { data } = useFrame();
    const { mainMenu = [], metaMenu } = data?.navigation || {};
    const forceClose = useMedia(`(min-width: ${breakpointsRaw.md}px)`, true);

    /**
     * Close in case the user breaks out of mobile
     * breakpoint - while open.
     */
    useEffect(() => {
        if (forceClose) {
            close();
        }
    }, [forceClose]);

    return (
        <Drawer open={isOpen} onClose={close}>
            {isOpen && <MobileMegaMenu menu={mainMenu} metaMenu={metaMenu} />}
        </Drawer>
    );
};
