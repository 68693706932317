import type { DataKey, ExactMatches, Hit, PrimaryCategory } from './search.definitions';

/**
 * Sanitizes a string for URL usage by trimming, converting to lowercase,
 * replacing spaces with hyphens, Danish characters with their equivalents,
 * and removing multiple hyphens.
 */
export const sanitizeStringForUrl = (input: string) => {
    return input
        .trim()
        .toLowerCase()
        .replace(/\s+>/g, '/')
        .replace(/\s+/g, '-')
        .replace(/æ/g, 'ae')
        .replace(/ø/g, 'oe')
        .replace(/å/g, 'aa')
        .replace(/-+/g, '-');
};

/**
 * Builds a category path from a given category string.
 * Splits the category by ' > ', sanitizes each part, and joins them with '/'.
 */
export const buildCategoryPath = (category: string) => {
    return category
        .split(' > ')
        .map((part) => sanitizeStringForUrl(part))
        .join('/');
};

/**
 * Finds a category match from the primary category that matches the query.
 */
export const findCategoryMatch = (primaryCategory: PrimaryCategory, query: string) => {
    const targetQuery = query.trim().toLowerCase();
    return (
        Object.values(primaryCategory).find((category) => {
            const lastPart = category.split(' > ').pop()?.trim().toLowerCase();
            return lastPart === targetQuery;
        }) || null
    );
};

export const getDefaultSearchPath = (query: string) => `/search?query=${encodeURIComponent(query)}`;

export const getBrandMatchPath = (query: string, exactMatches: ExactMatches | undefined) => {
    const targetQuery = query.toLowerCase();
    const brand = exactMatches?.brand?.[0]?.value?.toLowerCase();
    if (brand && brand === targetQuery) {
        return `/brands/${sanitizeStringForUrl(brand)}`;
    }
    return null;
};

export const getCategoryMatchPath = (query: string, exactMatches: ExactMatches | undefined) => {
    const targetQuery = query.toLowerCase();
    const categories: string[] = [];

    // Iterate through primary categories to find a match
    for (let i = 0; i < 5; i++) {
        const categoryKey = `__primary_category.${i}`;
        const categoryData = exactMatches?.[categoryKey];

        if (categoryData && categoryData.length > 0) {
            const value = categoryData[0].value;
            const lastPart = value.split(' > ').pop()?.toLowerCase();

            if (lastPart) {
                categories.push(lastPart);

                if (lastPart === targetQuery) {
                    return `/${categories.map(sanitizeStringForUrl).join('/')}`;
                }
            }
        }
    }

    return null;
};

export const getSearchPath = (hit: Hit, algoliaProductsIndex: string): string => {
    const dataKey = Object.keys(hit).find((key) => key === algoliaProductsIndex);
    if (!dataKey) return getDefaultSearchPath(hit.query);

    //TODO assertions will be fixed in IMC-514
    const exactMatches = (hit[dataKey] as DataKey)?.facets?.exact_matches;
    if (!exactMatches) return getDefaultSearchPath(hit.query);

    const brandMatchPath = getBrandMatchPath(hit.query, exactMatches);
    if (brandMatchPath) return brandMatchPath;

    const categoryPath = getCategoryMatchPath(hit.query, exactMatches);
    return categoryPath || getDefaultSearchPath(hit.query);
};
