import produce from 'immer';
import { createContext } from 'react';
import create, { StateCreator, StoreApi, UseBoundStore } from 'zustand';
import { searchStateToURL } from '~/lib/algolia';

export interface ISearchStore {
    query: string;
    setQuery: (query: string) => void;
    showSearchPage: boolean;
    setShowSearch: (showSearchPage: boolean) => void;
    hits?: number;
    updateHits: (hits: number) => void;
    searchState: Record<string, any>;
    setSearchState: (state: ISearchStore['searchState']) => void;
    stateAsUrl: string;
    setIsSamePage: (isSame: boolean) => void;
    isSamePage: boolean;
}

const useSearchStoreBase: StateCreator<ISearchStore, [], [], ISearchStore> = (set, get) => ({
    query: '',
    updateHits: (hits: number) => set({ hits }),
    showSearchPage: false,
    setShowSearch: (showSearchPage: boolean) =>
        set({ showSearchPage, query: showSearchPage ? get().query : '' }),
    isSamePage: false,
    setIsSamePage: (isSamePage) => set({ isSamePage }),
    searchState: {
        query: '',
        page: 1,
        primary_category_id: '',
        filters: '',
        productids: null,
        campaign: null,
    },
    setQuery: (query: string) => {
        const state = get().searchState;
        const updated = produce(state, (draft) => {
            draft.query = query;
            draft.page = 1;
            draft.primary_category_id = '';
            draft.filters = '';
            draft.productids = null;
        });
        const asUrl = searchStateToURL(updated);
        set(
            produce((draft) => {
                draft.query = query;
                draft.stateAsUrl = asUrl;
                draft.searchState.query = query;
                draft.searchState.page = 1;
                draft.searchState.primary_category_id = '';
                draft.searchState.filters = '';
            })
        );
    },

    stateAsUrl: '',
    setSearchState: (searchState) => {
        const stateAsUrl = searchStateToURL(searchState);
        set(() => ({ searchState, stateAsUrl }));
    },
});

export const useSearchStore = create<ISearchStore>(useSearchStoreBase);
export const useMobileSearchStore = create<ISearchStore>(useSearchStoreBase);
export const SearchContext = createContext<UseBoundStore<StoreApi<ISearchStore>>>(useSearchStore);
