import { Image, Text } from '$shared/components';
import { StyledResetButton } from '$shared/components/Button/styled';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { mq } from '~/lib';

const StyledAnchor = StyledResetButton.withComponent('a');

export const StyledItem = styled(StyledAnchor)(({ theme }) => ({
    position: 'relative',
    fontSize: theme.fontSizes.sm,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
}));

export const StyledListItem = styled(StyledAnchor)(({ theme }) => ({
    position: 'relative',
    height: '56px',
    fontSize: theme.fontSizes.sm,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '0 16px',
    color: theme.colors.black,
    borderBottom: `1px solid ${theme.colors.brownLight}`,
    '&:active': {
        color: theme.colors.black,
        textDecoration: 'none',
    },
    '&:visited': {
        color: theme.colors.black,
        textDecoration: 'none',
    },
    '&:hover': {
        color: theme.colors.black,
        textDecoration: 'none',
    },
}));

export const StyledFlexItem = styled.div<{ auto?: boolean }>(
    {
        flex: 1,
    },
    ifProp('auto', {
        flex: '0 auto',
        height: '40px',
        width: '40px',
    })
);

export const StyledContentContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    left: 'auto',
    right: 'auto',
    bottom: theme.space[3],
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
}));

export const StyledContent = styled.div<{ truncate?: boolean }>(
    ({ theme }) => ({
        lineHeight: 1.2,
        color: theme.colors.white,
    }),
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }))
);

export const StyledSuffix = styled.div({
    textAlign: 'right',
    color: 'lightgrey',
    paddingLeft: 15,
    lineHeight: '25px',
    height: 25,

    svg: {
        height: 25,
        width: 25,
    },
});

export const StyledImage = styled(Image)({});
export const Gradient = styled.div({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '68px',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(1,1,1,0.75)) 100%',
});

export const NodeText = styled(Text)(({ theme }) => ({
    color: theme.colors.white,
    padding: theme.space[1],
    textTransform: 'uppercase',
    [mq(0, 'md')]: {
        ...theme.mixins.useTextStyle('mobileMenuImageText'),
    },
}));

export const CenterText = styled.div({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
