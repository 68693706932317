import React from 'react';
import { NavigationNode } from '~/lib';
import Flex from '~/shared/components/Flex';
import { Text } from '~/shared/components/';
import { useMobileMenuState } from '../../context/MobileMenuContext';
import { useMobileMenu } from '../../hooks/useMobileMenu';
import {
    MenuHeaderContainer,
    StyledLogo,
    StyledClose,
    StyledCloseButton,
    StyledArrowLeft,
    HeaderHeroMenu,
    StyledArrowLeftButton,
    StyledButtonContainer,
    MenuHeaderFlexContent,
    StyledLink,
    Gradient,
    StyledLastMenu,
    StyledLinkAll,
    StyledLoginButtonContainer,
} from './styled';
import { theme } from '~/theme';
import { LoginButton } from '../../../N20MainMenu/components/LoginButton';

type Props = {
    goBackHandler: () => void;
    node?: NavigationNode;
};

export const MenuHeader = ({ goBackHandler, node }: Props) => {
    const { close } = useMobileMenu();
    const { activeNode, isLastNode } = useMobileMenuState();
    const menuHero = activeNode?.image;
    const showHeaderImage = node && !isLastNode && menuHero;
    return (
        <MenuHeaderContainer minHeight={!!showHeaderImage} borderShadow={!showHeaderImage}>
            <StyledLoginButtonContainer>
                {node && (
                    <StyledArrowLeftButton
                        styleColor={!!showHeaderImage}
                        variant="plain"
                        onClick={goBackHandler}
                    >
                        <StyledArrowLeft />
                    </StyledArrowLeftButton>
                )}
                {!node && (
                    <LoginButton
                        textColor={theme.colors.black}
                        desktop
                        metaMenu
                        onClick={close}
                    ></LoginButton>
                )}
            </StyledLoginButtonContainer>
            <MenuHeaderFlexContent grow={1}>
                {!node && (
                    <Flex justifyContent="center" alignItems="center">
                        {!node && <StyledLogo />}
                    </Flex>
                )}
            </MenuHeaderFlexContent>
            {showHeaderImage && (
                <>
                    <HeaderHeroMenu backgroundImg={menuHero?.src}>
                        <Text variant="display1" as="h2">
                            {activeNode?.link.title}
                        </Text>
                    </HeaderHeroMenu>
                    <Gradient></Gradient>
                    {!!activeNode?.link?.url && !!activeNode?.alternateLinkHeader && (
                        <StyledLink
                            aria-label={activeNode.alternateLinkHeader}
                            onClick={() => close()}
                            href={activeNode.link.url}
                            target={activeNode.link.target}
                            title={activeNode.link.title}
                        >
                            {activeNode.alternateLinkHeader}
                        </StyledLink>
                    )}
                </>
            )}
            {isLastNode && !!activeNode?.link?.url && !!activeNode?.alternateLinkHeader && (
                <StyledLastMenu>
                    <strong>{activeNode.link.title}</strong>
                    <StyledLinkAll
                        aria-label={activeNode.alternateLinkHeader}
                        href={activeNode.link.url}
                        target={activeNode.link.target}
                        title={activeNode.link.title}
                        onClick={() => close()}
                    >
                        {activeNode.alternateLinkHeader}
                    </StyledLinkAll>
                </StyledLastMenu>
            )}
            <StyledButtonContainer>
                <StyledCloseButton
                    aria-label="Close"
                    styleColor={!!showHeaderImage}
                    variant="plain"
                    onClick={close}
                >
                    <StyledClose />
                </StyledCloseButton>
            </StyledButtonContainer>
        </MenuHeaderContainer>
    );
};
