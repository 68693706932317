import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledBackdrop = styled(motion.div)(({ theme: { colors } }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    bottom: 0,
    backgroundColor: colors.white,
}));
