/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { StyledHeaderSmallIconOptionTextUsp, StyledSmallIconButtonUsp } from '../styled';
import { UspItem } from '~/lib';
import { Image, Link } from '~/shared/components';
type Props = { desktop?: boolean; textColor?: string; section: UspItem };
export const UspMetaMenuItem = (props: Props) => {
    const { desktop, textColor, section } = props;

    const StyledSmallIconButtonUspElement = () => (
        <StyledSmallIconButtonUsp textColor={textColor}>
            <Image
                src={section.image?.src as string}
                width={16}
                height={16}
                layout="intrinsic"
                alt={section.image?.alt ?? ''}
                color={textColor}
                disableSkeleton
            />
            <StyledHeaderSmallIconOptionTextUsp desktop={desktop} textColor={textColor}>
                {section.headline}
            </StyledHeaderSmallIconOptionTextUsp>
        </StyledSmallIconButtonUsp>
    );

    return (
        <>
            {section.link ? (
                <Link noHover href={section.link.url} {...section.link}>
                    <StyledSmallIconButtonUspElement />
                </Link>
            ) : (
                <StyledSmallIconButtonUspElement />
            )}
        </>
    );
};
