import styled from '@emotion/styled';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { breakpoints } from '~/theme/breakpoints';

export const StyledIconButtonCloseCampaignBanner = styled(StyledResetButton)<{ textColor: string }>(
    ({ textColor, theme }) => ({
        padding: theme.space[3],
        position: 'absolute',
        right: '0px',
        '&:hover': {
            backgroundColor: 'unset',
        },
        svg: {
            width: 16,
            height: 16,
            '& path': {
                fill: textColor,
            },
        },
        [breakpoints.md]: {
            display: 'none',
        },
    })
);
