import React from 'react';
import Basket from '$icons/basket.svg';
import { useBasket } from '~/features/basket';
import { StyledHeaderSmallIconOptionText, StyledIconBadge, StyledSmallIconButton } from '../styled';
import { useTranslation } from '~/shared/utils/translation';
import { useFrame } from '~/shared/utils';
import { AnimatePresence } from 'framer-motion';
type Props = { textColor: string; desktop?: boolean; metaMenu: boolean };
export const MiniBasketButton = (props: Props) => {
    const { translate } = useTranslation();
    const { data: frame } = useFrame();
    const { itemAccumulatedCount } = useBasket();
    const { textColor, desktop, metaMenu } = props;

    const count = itemAccumulatedCount();

    const basketTrigger = (
        <StyledSmallIconButton
            desktop={desktop}
            textColor={textColor}
            href={frame?.settings?.pageReferences?.basketPage?.url}
        >
            <Basket />
            <StyledHeaderSmallIconOptionText
                desktop={desktop}
                textColor={textColor}
                metaMenu={metaMenu}
            >
                {translate('header.basket')}
            </StyledHeaderSmallIconOptionText>
            {count > 0 ? (
                <AnimatePresence initial>
                    <StyledIconBadge
                        children={count}
                        key={'basket' + count}
                        animate={{ scale: [1, 1.2, 1] }}
                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                    />
                </AnimatePresence>
            ) : null}
        </StyledSmallIconButton>
    );

    return basketTrigger;
};
