import Close from '$icons/close.svg';
import React from 'react';
import { useTranslation } from '~/shared/utils/translation';
import { StyledIconButtonCloseCampaignBanner } from './styled';

type Props = { textColor: string; onCloseBanner?: () => void };
export const CloseBanner = (props: Props) => {
    const { translate } = useTranslation();
    return (
        <StyledIconButtonCloseCampaignBanner
            onClick={props.onCloseBanner}
            children={<Close />}
            title={translate('header.closeBanner')}
            aria-label={translate('header.closeBanner')}
            textColor={props.textColor}
        />
    );
};
