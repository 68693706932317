import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Link } from '~/shared/components';

export type LinkVariant = 'header' | 'subHeader' | 'link';

export const StyledNavigationLink = styled(Link)<{ variant?: LinkVariant }>(
    ({ theme: { colors } }) => ({
        display: 'inline-block',
        color: colors.grey90,
        '&:hover': {
            color: colors.black,
        },
    }),
    switchProp('variant', {
        header: ({ theme }) => ({
            fontSize: `${theme.fontSizes.xl}`,
            fontWeight: theme.fontWeights.bold,
            marginBottom: theme.space[1],
        }),
        subHeader: ({ theme }) => ({
            fontSize: `${theme.fontSizes.xs}`,
            padding: `0px ${theme.space[1]} 0px 0px`,
        }),
        link: ({ theme }) => ({
            ...theme.mixins.useTextStyle('bodySmall'),
            marginBottom: theme.space[2],
        }),
    }),
);
