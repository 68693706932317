import { useCallback, useEffect } from 'react';
import { useScrollLock } from '~/shared/hooks/useScrollLock';
import { useMobileMenuState } from './useMobileMenuState';

type TMobileMenu = {
    scrollLock?: boolean;
};

const defaultOptions: TMobileMenu = {
    scrollLock: true,
};
/**
 * Use to open and close the mobile menu.
 * It ensures a scroll lock when open.
 */
export const useMobileMenu = (options?: TMobileMenu) => {
    const { scrollLock } = { ...defaultOptions, ...options };

    const { isOpen, setIsOpen } = useMobileMenuState();
    const { lock, unlock } = useScrollLock();

    const open = () => {
        setIsOpen(true);

        if (scrollLock) {
            lock();
        }
    };

    const close = useCallback(() => {
        setIsOpen(false);
        if (scrollLock) {
            unlock();
        }
    }, [scrollLock]);

    const toggle = () => {
        isOpen ? close() : open();
    };

    useEffect(() => {
        if (scrollLock && !isOpen) {
            unlock();
        }
    }, [scrollLock, isOpen]);

    return {
        isOpen,
        setIsOpen,
        open,
        close,
        toggle,
    };
};
