import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';

export const StyledListItem = styled.li({
    '&:last-child button': {
        borderBottom: 'none',
    },
    ':last-child a': {
        borderBottom: 'none',
    },
});

export const StyledUl = styled.ul<{ singleColumn?: boolean }>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.space[2],
        [breakpoints.xs]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.space[2],
        },
    }),
    ifProp('singleColumn', {
        [breakpoints.xs]: {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
    })
);
