import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { memo } from 'react';
import { PromotedNavigationNode } from '~/lib/data-contract';
import { Image } from '~/shared/components/Image';
import {
    StyledCaption,
    StyledCoverLink,
    StyledPromotedContent,
    StyledPromotedItem,
} from './styled';

/**
 * A list of link images, with optional texts
 */

export const PromotedChildren = memo(({ nodes }: { nodes?: PromotedNavigationNode[] }) => (
    <StyledPromotedContent>
        {nodes?.map(({ image, link, text }, i) => (
            <StyledPromotedItem key={i}>
                {image?.src && <Image {...image} />}
                {text && <StyledCaption variant="body" children={text} />}

                {link?.url && (
                    <StyledCoverLink href={link.url} {...link}>
                        <VisuallyHidden.Root>{text}</VisuallyHidden.Root>
                    </StyledCoverLink>
                )}
            </StyledPromotedItem>
        ))}
    </StyledPromotedContent>
));
