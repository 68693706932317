import { createContext, FC, useContext, useState } from 'react';
import { NavigationNode } from '~/lib/data-contract';

type MobileMenuContextState = {
    activeNode: NavigationNode | undefined;
    isFirstNode: boolean | undefined;
    isLastNode: boolean | undefined;
};

type Actions = {
    setActiveNode: (payload: NavigationNode | undefined) => void;
};

export const MobileMenuStateContext = createContext<MobileMenuContextState | undefined>(undefined);
export const MobileMenuProviderContext = createContext<Actions | undefined>(undefined);

export const MobileMenuProvider: FC = ({ children }) => {
    const [state, setState] = useState<MobileMenuContextState>({
        activeNode: undefined,
        isFirstNode: undefined,
        isLastNode: undefined,
    });

    const setActiveNode = (payload: NavigationNode | undefined) => {
        const isLastNode =
            payload?.children?.filter((item: NavigationNode) => !!item.children).length === 0;
        setState((prev) => ({
            ...prev,
            activeNode: payload,
            isFirstNode: !payload,
            isLastNode,
        }));
    };

    return (
        <MobileMenuStateContext.Provider value={state}>
            <MobileMenuProviderContext.Provider value={{ setActiveNode }} children={children} />
        </MobileMenuStateContext.Provider>
    );
};

export function useMobileMenuState() {
    const context = useContext(MobileMenuStateContext);

    if (context === undefined) {
        throw new Error('useMobileMenuState must be used within the MobileMenuProvider');
    }

    return context;
}

export function useMobileMenuDispatch() {
    const context = useContext(MobileMenuProviderContext);

    if (context === undefined) {
        throw new Error('useMobileMenuDispatch must be used within the MobileMenuProvider');
    }

    return context;
}
