import React, { useEffect, useRef } from 'react';
import { NavigationNode } from '~/lib/data-contract';
import { NodeList } from '../NodeList';
import { StyledMenuPage } from './styled';

type MenuPageProps = {
    nodes: NavigationNode[];
    onSelectNode?: (state?: NavigationNode) => void;
};

export const MenuPage = ({ nodes, onSelectNode }: MenuPageProps) => {
    const ref = useRef<HTMLDivElement>(null);
    /**
     * Focus first link on open
     */
    useEffect(() => {
        const element = ref.current;

        if (element) {
            const firstFocusableElement = element.querySelector<HTMLAnchorElement>('a, button');
            firstFocusableElement?.focus();
        }
    }, [nodes]);

    /**
     * Support keyboard navigation up and down within the navigation
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        const activeElement = document.activeElement as HTMLAnchorElement;
        const focusableElements =
            ref.current?.querySelectorAll<HTMLAnchorElement>('a, button') || [];
        const currentFocusIndex = Array.from(focusableElements).indexOf(activeElement);

        if (event.key === 'ArrowDown') {
            const focusIndex = Math.min(currentFocusIndex + 1, focusableElements.length - 1);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            const focusIndex = Math.max(currentFocusIndex - 1, 0);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        }
    };

    return (
        <StyledMenuPage ref={ref} onKeyDown={onKeyDownHandler}>
            {nodes?.length ? <NodeList nodes={nodes} onSelectNode={onSelectNode} /> : null}
        </StyledMenuPage>
    );
};
