import React, { memo, useEffect, useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';
import { StyledBackdrop } from './styled';

type Props = {
    imitate?: HTMLElement | null;
};

/**
 * Mimics an elements height based on it's `imitator` prop
 * handles resizing
 */
export const Backdrop = memo(({ imitate }: Props) => {
    const { width } = useWindowSize();
    const [backdropHeight, setBackdropHeight] = useState<number>(0);

    useEffect(() => {
        setBackdropHeight(imitate?.offsetHeight ?? 0);
    }, [imitate]);

    useDebounce(
        () => {
            setBackdropHeight(imitate?.offsetHeight ?? 0);
        },
        50,
        [width]
    );

    return (
        <StyledBackdrop
            animate={{ height: backdropHeight }}
            transition={{ ease: [0.25, 0, 0, 1], duration: 0.55 }}
        />
    );
});
