import { mq } from '$lib';
import styled from '@emotion/styled';
import Chevron from '$icons/chevron.svg';
import { breakpoints } from '~/theme/breakpoints';
import { Container } from '../Container';
import { StyledLink } from '../Link';

export const BreadcrumbsContainer = styled(Container)<{ isFloating: boolean }>(
    ({ theme, isFloating }) => ({
        position: isFloating ? 'absolute' : undefined,
        top: isFloating ? 0 : undefined,
        right: isFloating ? 0 : undefined,
        left: isFloating ? 0 : undefined,
        zIndex: theme.zIndices['1'],
        padding: `0 ${theme.space[3]}`,
        background: isFloating
            ? 'linear-gradient(180deg, rgba(0,0,0,0.5) 0%, transparent)'
            : undefined,
        [breakpoints.md]: {
            padding: `0 ${theme.space[5]}`,
            background: 'none',
        },
    })
);

export const BreadcrumbsList = styled.ul(({ theme }) => ({
    display: 'flex',
    margin: '0 auto',
    padding: `${theme.space[3]} 0`,
    overflow: 'auto',
    whiteSpace: 'nowrap',

    [breakpoints.md]: {
        padding: `${theme.space[4]} 0`,
    },
}));

export const BreadcrumbsItem = styled.li<{ color: string }>(({ theme, color }) => ({
    ...theme.mixins.useTextStyle('bodySmall'),

    display: 'flex',
    alignItems: 'center',
    marginRight: theme.space[2],
    color: color,

    alignSelf: 'center',

    a: {
        fontWeight: theme.fontWeights.medium,
    },

    '&:not(:nth-last-of-type(-n+3))': {
        [mq(0, 'md')]: {
            display: 'none',
        },
    },

    [`${StyledLink}`]: {
        fontWeight: theme.fontWeights.medium,
    },
}));

export const StyledChevron = styled(Chevron)(({ theme }) => ({
    width: 16,
    height: 16,
    padding: theme.space[1],
    marginLeft: theme.space[2],
}));
