import React from 'react';
import { StyledHeaderSmallIconOptionText, StyledSmallIconButton } from '../styled';
import Link from 'next/link';
import { NavigationNode } from '~/lib';
import { Image } from '~/shared/components';
type Props = {
    desktop?: boolean;
    textColor?: string;
    section: NavigationNode;
    metaMenu: boolean;
};
export const DesktopMetaMenuItem = (props: Props) => {
    const { desktop, textColor, section, metaMenu } = props;
    return (
        <Link href={section.link.url ?? '/'} passHref legacyBehavior>
            <StyledSmallIconButton desktop={desktop} textColor={textColor}>
                <>
                    <Image
                        src={section.image?.src as string}
                        width={16}
                        height={16}
                        layout="intrinsic"
                        alt={section.image?.alt ?? ''}
                        color={textColor}
                        disableSkeleton
                    />
                    <StyledHeaderSmallIconOptionText
                        desktop={desktop}
                        textColor={textColor}
                        metaMenu={metaMenu}
                    >
                        {section.link.title}
                    </StyledHeaderSmallIconOptionText>
                </>
            </StyledSmallIconButton>
        </Link>
    );
};
