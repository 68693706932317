import React, { memo } from 'react';
import { Link } from '~/lib/data-contract';

import { LinkVariant, StyledNavigationLink } from './styled';

type Props = {
    link?: Link;
    /**
     * - 'header'       Top level link
     * - 'subHeader'    Sub level link for column header links
     * - 'link'         Sub level children link
     */
    variant?: LinkVariant;
};

/**
 * Navigation link with varieties.
 *
 */
export const NavigationLink = memo(({ link, variant = 'link' }: Props) => {
    if (!link?.url) {
        return <></>;
    }

    return (
        <StyledNavigationLink
            prefetch={false}
            href={link.url}
            variant={variant}
            children={link.text}
            {...link}
        />
    );
});
