import create from 'zustand';
type Banner = {
    isBannerOpen: boolean;
    setIsBannerOpen: (isBannerOpen: boolean) => void;
};

export const useBannerState = create<Banner>((set, get) => ({
    isBannerOpen: true,
    setIsBannerOpen: (isBannerOpen) => {
        if (isBannerOpen === get().isBannerOpen) return;

        set({ isBannerOpen });
    },
}));
