import Head from 'next/head';

type Props = {
    schemaMarkup: string;
};

export const StandardPageSchema = ({ schemaMarkup }: Props) => {
    // https://www.hillwebcreations.com/valid-image-size-json-ld-snippet-markup/#:~:text=%E2%80%9CImages%20must%20be%20at%20least,most%201920%C3%971080%20pixels.
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: `
                    ${JSON.stringify(JSON.parse(schemaMarkup))}
             `,
                }}
            >
                {}
            </script>
        </Head>
    );
};
