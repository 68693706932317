import Profile from '$icons/profile.svg';
import React from 'react';
import { useLoginDrawer } from '../../../../login/hooks';
import { StyledHeaderSmallIconOptionText, StyledIconButtonLogin } from '../styled';
import { useTranslation } from '~/shared/utils/translation';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { useFrame } from '~/shared/utils';
import { useRouter } from 'next/router';

type Props = {
    desktop?: boolean;
    textColor?: string;
    metaMenu: boolean;
    checkout?: boolean;
    mobile?: boolean;
    onClick?: () => void;
    className?: string;
};
export const LoginButton = (props: Props) => {
    const { translate } = useTranslation();
    const { isPlusMember, isStorePersonnel } = useCustomer();
    const { data } = useFrame();
    const { push } = useRouter();
    const { textColor, desktop, metaMenu, checkout, mobile, onClick, className } = props;

    const { open } = useLoginDrawer();

    const isLoggedIn = isPlusMember || isStorePersonnel;

    return (
        <StyledIconButtonLogin
            data-testid="login-profile"
            mobile={mobile}
            checkout={checkout}
            desktop={desktop}
            textColor={textColor}
            className={className}
            href="/"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (isLoggedIn) {
                    push(data.settings?.pageReferences?.myProfilePage?.url ?? '/');
                } else {
                    open('login');
                }

                onClick?.();
            }}
        >
            <Profile />{' '}
            <StyledHeaderSmallIconOptionText
                desktop={desktop}
                checkout={checkout}
                textColor={textColor}
                metaMenu={metaMenu}
            >
                {isPlusMember || isStorePersonnel
                    ? translate('login.profile')
                    : translate('header.login')}
            </StyledHeaderSmallIconOptionText>
        </StyledIconButtonLogin>
    );
};
