import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React from 'react';
import { MaxWidth } from '~/shared/components';
import { breakpoints } from '~/theme/breakpoints';

export const StyledNavigation = styled.nav({
    position: 'relative',
});

export const StyledList = styled.ul(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.space[4],
}));

export const StyledDropDownContainer = styled.div<{ headerRef: React.RefObject<HTMLElement> }>(
    ({ headerRef, theme }) => ({
        maxHeight: `calc(100vh - ${headerRef.current?.offsetHeight}px)`,
        backgroundColor: theme.colors.white,
        position: 'absolute',
        display: 'block',
        left: 0,
        right: 0,
        overflowY: 'auto',
    })
);

export const StyledDropDown = styled(motion.div)(({ theme }) => ({
    boxShadow: 'inset 0 10px 10px 0 rgb(0 0 0 / 8%)',
    [breakpoints.reducedMotion]: {
        backgroundColor: theme.colors.white,
    },
}));

export const StyledDropDownContent = styled(MaxWidth)(({ theme: { space, sizes } }) => ({
    display: 'flex',
    gap: space[5],
    padding: `${space[5]} ${space[5]}`,
    maxWidth: sizes.pageMaxWidth,
}));
