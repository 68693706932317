import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button/Button';
import { ifProp } from 'styled-tools';
import { theme } from '~/theme';
import Logo from '$icons/logo.svg';
import Flex from '~/shared/components/Flex';
import Close from '$icons/close.svg';
import ArrowLeft from '$icons/chevron-left.svg';
import Profile from '$icons/profile.svg';
import FlexContent from '~/shared/components/FlexGrowContent';
import { Link } from '~/shared/components';

export const LoginButton = styled(Profile)({
    color: theme.colors.black,
});
export const LoginButtonAction = styled(Button)({
    ...theme.mixins.useTextStyle('bodySmall'),
    color: theme.colors.black,

    display: 'flex',
    gap: theme.space[2],
    alignItems: 'center',
    textTransform: 'capitalize',
    background: 'none',
    padding: 0,
    svg: {
        height: 16,
        width: 16,
    },
    '&:hover': {
        background: 'none',
        color: theme.colors.black,
    },
    '&:active': {
        background: 'none',
    },
});
export const LoginText = styled.p({
    fontSize: theme.fontSizes.sm,
});
export const StyledArrowLeftButton = styled(Button)<{ styleColor: boolean }>(
    {
        svg: {
            height: 16,
            width: 16,
            fill: 'transparent',
            color: theme.colors.black,
            '& path:nth-of-type(1)': {
                fill: 'transparent',
            },
            '& path:nth-of-type(2)': {
                fill: 'transparent',
                stroke: theme.colors.black,
            },
        },
        zIndex: theme.zIndices[1],
    },
    ifProp('styleColor', {
        svg: {
            fill: 'transparent',
            color: theme.colors.white,
            '& path:nth-of-type(1)': {
                fill: 'transparent',
            },
            '& path:nth-of-type(2)': {
                fill: 'transparent',
                stroke: theme.colors.white,
            },
        },
    })
);

export const StyledLoginButtonContainer = styled.div({
    flexBasis: 0,
    flexGrow: 1,
    display: 'flex',
    padding: `0 0`,
});

export const StyledButtonContainer = styled(StyledLoginButtonContainer)({
    justifyContent: 'flex-end',
});
export const StyledCloseButton = styled(Button)<{ styleColor: boolean }>(
    {
        svg: {
            height: 16,
            width: 16,
            fill: 'transparent',
            color: theme.colors.black,
            '& path:nth-of-type(1)': {
                fill: 'transparent',
            },
        },
        zIndex: theme.zIndices[1],
    },
    ifProp('styleColor', {
        svg: {
            fill: 'transparent',
            color: theme.colors.white,
            '& path:nth-of-type(1)': {
                fill: 'transparent',
            },
        },
    })
);

export const StyledArrowLeft = styled(ArrowLeft)({});
export const StyledClose = styled(Close)({});
export const StyledLogo = styled(Logo)({
    height: 22,
    width: 150,
});

export const MenuHeaderContainer = styled(Flex)<{
    minHeight: boolean;
    borderShadow: boolean;
}>(
    ({ theme }) => ({
        padding: `${theme.space[5]} ${theme.space[3]} ${theme.space[2]} ${theme.space[3]}`,
        position: 'relative',
        flexWrap: `wrap`,
        backgroundColor: theme.colors.white,
    }),
    ifProp('minHeight', {
        height: theme.minHeights['mobileHero'],
        alignItems: 'flex-start',
    }),
    ifProp('borderShadow', {
        boxShadow: `0 2px 20px 0 rgba(0,0,0,0.2)`,
    })
);

export const MenuHeaderFlexContent = styled(FlexContent)({
    display: 'flex',
    justifyContent: 'center',
});

export const HeaderHeroMenu = styled.div<{ backgroundImg?: string }>(
    ({ theme }) => ({
        height: `${theme.space[6]} ${theme.space[3]} ${theme.space[3]} ${theme.space[3]}`,
    }),
    ifProp('backgroundImg', ({ backgroundImg }) => ({
        display: 'flex',
        alignItems: 'center',
        height: theme.minHeights['mobileHero'],
        background: `url(${backgroundImg}) center center`,
        backgroundSize: 'cover',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        h2: {
            ...theme.mixins.useTextStyle('display1'),
            color: theme.colors.white,
            textTransform: 'uppercase',
            textAlign: 'center',
            width: '100%',
            wordBreak: 'break-all',
        },
    }))
);
export const StyledLink = styled(Link)({
    color: theme.colors.white,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    bottom: '40px',
    left: 0,
    textDecoration: 'underline',
});
export const StyledLinkAll = styled(Link)({
    color: theme.colors.black,
    textDecoration: 'underline',
    fontSize: theme.fontSizes['xs'],
});

export const Gradient = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 58%, rgba(0,0,0,0.5))',
});
export const StyledLastMenu = styled.div({
    marginTop: '16px',
    order: 2,
    clear: 'both',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    strong: {
        ...theme.mixins.useTextStyle('bodySmall'),
        textTransform: 'uppercase',
    },
});
