import { useEffect, useRef, useState } from 'react';
import { breakpointsRaw } from '~/theme/breakpoints';

const isServer = typeof window === 'undefined';

export function useIsMobile({ width = breakpointsRaw.md } = {}) {
    const [isMobileState, setIsMobile] = useState(false);
    const resizeEventRef = useRef(false);

    function handleResize() {
        if (!isServer) {
            if (global.window.innerWidth < width) {
                if (!isMobileState) {
                    setIsMobile(true);
                }
            } else if (isMobileState) {
                setIsMobile(false);
            }
        }
    }

    useEffect(() => {
        if (!isServer) {
            if (!resizeEventRef.current) {
                global.window.addEventListener('resize', handleResize);
                resizeEventRef.current = true;
            }

            handleResize();

            return () => {
                if (resizeEventRef.current) {
                    global.window.removeEventListener('resize', handleResize);
                    resizeEventRef.current = false;
                }
            };
        }
    });

    return isMobileState;
}
