import { useEffect } from 'react';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { breakpointsRaw } from '~/theme/breakpoints';
import create from 'zustand';

export type menuType = 'Desktop' | 'Full' | 'Hidden';

type storeProps = {
    menuType: menuType;
    setMenuType: (menuType: menuType) => void;
    showToTopButton: boolean;
    setShowToTopButton: (setShowToTopButton: boolean) => void;
    toTopButtonOffset: number | null;
    setToTopButtonOffset: (setToTopButtonOffset: number | null) => void;
    amountScrolled: number;
    isAtTop: boolean;
    setAmountScrolled: (amountScrolled: number) => void;
    setIsAtTop: (atTop: boolean) => void;
};

const useMenuScrollStore = create<storeProps>((set) => ({
    menuType: 'Desktop',
    amountScrolled: 0,
    isAtTop: true,
    showToTopButton: false,
    toTopButtonOffset: null,
    setAmountScrolled: (amountScrolled: number) => {
        set({ amountScrolled });
    },
    setIsAtTop: (isAtTop: boolean) => {
        set({ isAtTop });
    },
    setMenuType: (menuType: menuType) => {
        set({ menuType });
    },
    setShowToTopButton: (showToTopButton: boolean) => {
        set({ showToTopButton });
    },
    setToTopButtonOffset: (toTopButtonOffset: number | null) => {
        set({ toTopButtonOffset });
    },
}));

export const useMenuScroll = () => {
    const {
        menuType,
        setMenuType,
        showToTopButton,
        setShowToTopButton,
        isAtTop,
        toTopButtonOffset,
        setToTopButtonOffset,
        amountScrolled,
        setAmountScrolled,
        setIsAtTop,
    } = useMenuScrollStore();

    function onAtTop() {
        setMenuType('Full');
        setShowToTopButton(false);
        setAmountScrolled(0);
    }

    function scrolledHitsDelta(scrolled: number, scrollDelta: number) {
        return scrolled > scrollDelta;
    }

    function onUp(scrolled: number, scrollDelta: number) {
        if (scrolledHitsDelta(scrolled * -1, scrollDelta)) {
            setMenuType('Full');
            setShowToTopButton(true);
            setAmountScrolled(0);
        } else if (window.scrollY < 200) {
            onAtTop();
        } else {
            setAmountScrolled(scrolled - 200);
        }
    }

    function onDown(scrolled: number, scrollDelta: number) {
        setMenuType('Hidden');

        if (scrolledHitsDelta(scrolled, scrollDelta)) {
            setShowToTopButton(false);

            setAmountScrolled(0);
        } else {
            setAmountScrolled(scrolled);
        }
    }

    function calc(prevPos: number, currPos: number) {
        const scrollDelta = window.innerHeight / 2;
        const direction = prevPos - currPos;
        const scrolled = amountScrolled + direction;
        const isScrollingDown = currPos < prevPos;
        const isScrollingUp = currPos > prevPos;
        const isAtTop = currPos + 500 >= 0;
        setIsAtTop(isAtTop);

        if (window.innerWidth >= breakpointsRaw.desktop) {
            setMenuType('Desktop');
            return;
        }

        if (isScrollingDown && !isAtTop) {
            onDown(scrolled, scrollDelta);
        } else if (isScrollingUp) {
            onUp(scrolled, scrollDelta);
        }
    }

    function forceShowMenu() {
        if (menuType !== 'Desktop') {
            setMenuType('Full');
        }
    }

    useScrollPosition(
        ({ prevPos, currPos }) => {
            calc(prevPos.y, currPos.y);
        },
        [amountScrolled],
        undefined,
        undefined,
        50,
    );

    const onResize = () => calc(window.scrollY, window.scrollY);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        setMenuType('Full');

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return {
        menuType,
        showToTopButton,
        toTopButtonOffset,
        setToTopButtonOffset,
        forceShowMenu,
        isAtTop,
    };
};
