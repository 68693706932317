import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { AnimationDirectionType, animationVariations } from './animations';
import { MenuPage } from '../MenuPage';
import { MetaNavigationNode, NavigationNode } from '~/lib/data-contract';
import { lookupParentNode } from '../../utils/lookupParentNode';
import { MetaMenu } from '../MetaMenu';
import { MenuHeader } from '../MenuHeader/MenuHeader';
import { MobileMenuProvider, useMobileMenuDispatch } from '../../context/MobileMenuContext';
import { useMobileMenu } from '../../hooks/useMobileMenu';

const StyledWrapper = styled.div({
    overflow: 'hidden',
});

export type MobileMegaMenuProps = {
    menu: NavigationNode[];
    metaMenu?: MetaNavigationNode;
    openMenu?: boolean;
};

type MenuState = {
    node?: NavigationNode;
    parentNode?: NavigationNode;
};

const MobileMegaMenuComponent = ({ menu, metaMenu }: MobileMegaMenuProps) => {
    const { setActiveNode } = useMobileMenuDispatch();
    const [animationDirection, setAnimationDirection] = useState<AnimationDirectionType>(
        'forwards'
    );
    const [activeMenuState, setActiveMenuState] = useState<MenuState>({});
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        let node;
        setActiveNode(node);
        setActiveMenuState(node ? { node, parentNode: lookupParentNode(node, menu) } : {});
        setIsReady(true);
    }, []);

    const onGoBackHandler = () => {
        onSelectNodeHandler(activeMenuState.parentNode);
        setAnimationDirection('backwards');
    };

    const onSelectNodeHandler = (node: NavigationNode | undefined) => {
        if (node) {
            const parentNode = lookupParentNode(node, menu);
            setActiveMenuState({ node, parentNode });
            setAnimationDirection('forwards');
        } else {
            // Go back to main menu
            setActiveMenuState({});
            setAnimationDirection('backwards');
        }

        setActiveNode(node);
    };
    const { isOpen } = useMobileMenu();
    const { node } = activeMenuState || {};

    useEffect(() => {
        if (!isOpen) {
            if (node) {
                setActiveMenuState({});
                setActiveNode(undefined);
            }
        }
    }, [isOpen]);

    if (!isReady) {
        return <></>;
    }

    return (
        <StyledWrapper>
            <AnimatePresence mode="wait" initial={false} custom={animationDirection}>
                <motion.div
                    key={node?.id || 'main'}
                    variants={animationVariations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    custom={animationDirection}
                    transition={{
                        type: 'tween',
                        duration: 0.15,
                        ease: [0.35, 0, 0.15, 1],
                    }}
                >
                    <MenuHeader goBackHandler={onGoBackHandler} node={node} />

                    {!node && (
                        <>
                            <MenuPage nodes={menu} onSelectNode={onSelectNodeHandler} />
                            {metaMenu && <MetaMenu metaMenu={metaMenu} />}
                        </>
                    )}

                    {node && (
                        <>
                            <MenuPage
                                nodes={node.children || []}
                                onSelectNode={onSelectNodeHandler}
                            />
                        </>
                    )}
                </motion.div>
            </AnimatePresence>
        </StyledWrapper>
    );
};

export const MobileMegaMenu = (props: MobileMegaMenuProps) => (
    <MobileMenuProvider>
        <MobileMegaMenuComponent {...props} />
    </MobileMenuProvider>
);
