import Heart from '$icons/heart.svg';
import React from 'react';
import { StyledHeaderSmallIconOptionText, StyledIconBadge, StyledSmallIconButton } from '../styled';
import { useTranslation } from '~/shared/utils/translation';
import { useFrame } from '~/shared/utils';
import Link from 'next/link';
import { useWishlist } from '~/features/commerce-api/hooks/useWishlist';

type Props = { textColor?: string; desktop?: boolean; metaMenu: boolean };
export const FavoriteButton = (props: Props) => {
    const { translate } = useTranslation();
    const { data } = useFrame();
    const { count } = useWishlist();
    const { textColor, desktop, metaMenu } = props;

    return data?.settings?.pageReferences.favoritesPage?.url ? (
        <Link passHref href={data.settings.pageReferences.favoritesPage.url} legacyBehavior>
            <StyledSmallIconButton desktop={desktop} textColor={textColor}>
                <Heart />{' '}
                <StyledHeaderSmallIconOptionText
                    desktop={desktop}
                    textColor={textColor}
                    metaMenu={metaMenu}
                >
                    {translate('header.favorit')}
                </StyledHeaderSmallIconOptionText>
                {count && count > 0 ? <StyledIconBadge children={count} /> : null}
            </StyledSmallIconButton>
        </Link>
    ) : null;
};
