import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { NavigationNode } from '~/lib/data-contract';
import {
    StyledList,
    StyledDropDown,
    StyledDropDownContent,
    StyledDropDownContainer,
} from './styled';
import { variants } from './animations';
import { Backdrop, Menu, NavigationItem, PromotedChildren } from './components';
import { useReducedMotion } from 'framer-motion';
import { useClickAway } from 'react-use';

export type Props = {
    navigation: NavigationNode[];
    headerRef: React.RefObject<HTMLDivElement>;
};

/**
 * A basic navigation with related mega menu.
 * covers basic SEO and accessibility needs.
 * Handles active/inactive state and close on route change.
 */
export const N30MegaMenu = memo(function N30MegaMenu({ navigation, headerRef }: Props) {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [activeNode, setActiveNode] = useState<HTMLElement | null>(null);
    const navigationRef = useRef<HTMLElement>(null);
    const { asPath } = useRouter();
    const shouldReduceMotion = useReducedMotion();

    const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | undefined>(undefined);

    const handleMouseEnter = useCallback((index: number | null) => {
        setDelayHandler(
            setTimeout(() => {
                setActiveIndex(index);
            }, 200),
        );
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (delayHandler) {
            clearTimeout(delayHandler);
            setDelayHandler(undefined);
        }
    }, [delayHandler]);

    useClickAway(navigationRef, () => {
        setActiveIndex(null);
    });

    useEffect(() => {
        setActiveIndex(null);
    }, [asPath]);

    const measuredRef = useCallback((node: HTMLDivElement) => {
        setActiveNode(node);
    }, []);

    const keyMap = useCallback(
        (key: string) =>
            (
                ({
                    Escape: () => setActiveIndex(null),
                }) as Record<string, () => void>
            )[key]?.(),
        [],
    );

    return (
        <nav
            onKeyDown={({ key }) => keyMap(key)}
            ref={navigationRef}
            onMouseLeave={() => setActiveIndex(null)}
            aria-label={'navigation'}
        >
            {!shouldReduceMotion && <Backdrop imitate={activeNode} />}
            <div>
                <StyledList>
                    {navigation?.map(({ children, promotedChildren, link, id, image }, index) => {
                        const isActive = index === activeIndex;
                        return (
                            <li key={id}>
                                <NavigationItem
                                    index={index}
                                    active={isActive || asPath === link?.url}
                                    onHover={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    subNodes={children}
                                    link={link}
                                    data-testid={`navigation-item-${index}`}
                                />
                                {!!children?.length && (
                                    <StyledDropDownContainer
                                        headerRef={headerRef}
                                        data-testid={`dropdown-container-${index}`}
                                    >
                                        <StyledDropDown
                                            variants={variants(shouldReduceMotion)}
                                            style={{ display: isActive ? 'block' : undefined }}
                                            animate={isActive ? 'active' : 'inactive'}
                                            initial="inactive"
                                        >
                                            <StyledDropDownContent
                                                ref={isActive ? measuredRef : undefined}
                                            >
                                                <Menu nodes={children} image={image} />
                                                {!!promotedChildren?.length && (
                                                    <PromotedChildren nodes={promotedChildren} />
                                                )}
                                            </StyledDropDownContent>
                                        </StyledDropDown>
                                    </StyledDropDownContainer>
                                )}
                            </li>
                        );
                    })}
                </StyledList>
            </div>
        </nav>
    );
});
