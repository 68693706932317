import React from 'react';
import { NavigationNode } from '~/lib/data-contract';
import { useMobileMenuState } from '../../context/MobileMenuContext';
import { NodeItem } from '../NodeItem';
import { StyledListItem, StyledUl } from './styled';

type NodeListProps = {
    nodes: NavigationNode[];
    onSelectNode?: (node: NavigationNode) => void;
};
export const NodeList = ({ nodes, onSelectNode }: NodeListProps) => {
    const { isFirstNode } = useMobileMenuState();
    return (
        <>
            <StyledUl singleColumn={!isFirstNode}>
                {nodes?.map((node, i) => (
                    <StyledListItem key={`${node.id}-${i}`}>
                        <NodeItem node={node} onSelectNode={onSelectNode} />
                    </StyledListItem>
                ))}
            </StyledUl>
        </>
    );
};
